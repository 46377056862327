import React, { Component } from "react"

class SocialLink extends Component {
  render() {
    let title = this.props.title
    let link = this.props.link
    let Icon = require('../images/social/Icon' + this.props.icon + '.svg')

    return (
      <li className="group__story--link">
        <a href={link} target="_blank" rel="noreferrer">
          <div className="group__story--icon">
            {<Icon />}
          </div>
          <span>{title}</span>
        </a>
      </li>
    );
  }
}

export default SocialLink
