import React, { Component } from "react"
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import "./css/socialShare.css";

class SocialShareBanner extends Component {
  render() {
    let {title, url, media} = this.props
    let itemStyle = {padding: '10px 0 0'};
    let iconSize = 32;

    media = process.env.BASE_URL + media

    return (
      <div className="share__banner">
        <EmailShareButton style={itemStyle} className="share__banner--item share__button--email" subject={title} url={url} >
          <EmailIcon size={iconSize} round />
        </EmailShareButton>

        <FacebookShareButton style={itemStyle} className="share__banner--item share__button--facebook" subject={title} url={url} >
          <FacebookIcon size={iconSize} round />
        </FacebookShareButton>

        <LinkedinShareButton style={itemStyle} className="share__banner--item share__button--linkedin" subject={title} url={url} >
          <LinkedinIcon size={iconSize} round />
        </LinkedinShareButton>

        <PinterestShareButton style={itemStyle} className="share__banner--item share__button--pinterest" subject={title} url={url} media={media} >
          <PinterestIcon size={iconSize} round />
        </PinterestShareButton>

        <TwitterShareButton style={itemStyle} className="share__banner--item share__button--twitter" subject={title} url={url} >
          <TwitterIcon size={iconSize} round />
        </TwitterShareButton>

        <WhatsappShareButton style={itemStyle} className="share__banner--item share__button--whatsapp" subject={title} url={url} >
          <WhatsappIcon size={iconSize} round />
        </WhatsappShareButton>
      </div>
    );
  }
}

export default SocialShareBanner
