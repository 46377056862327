import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import GoogleMapReact from 'google-map-react';

import Seo from '../components/seo';
import SocialShareBanner from '../components/socialShareBanner';
import SocialLink from '../components/socialLink';
import Slider from "../components/slider";
import "./css/group.css";

import styleList from "../../content/grid_style.json"

const isBrowser = typeof window !== "undefined";
const Isotope = isBrowser ? require("isotope-layout/js/isotope") : null

var timeoutHandle;

class Groep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cellSize: 25,
      iso: null,
      center: {
        lat: null,
        lng: null,
      },
      locationData: [],
      zoom: 12,
      initLayout: false,
      ready: false,
    };
    this.getLocation = this.getLocation.bind(this);
    this.fetchLocation = this.fetchLocation.bind(this);
    this.computCellData = this.computCellData.bind(this);
  }

  componentDidMount() {
    if (isBrowser) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      let t = this;
      this.getLocation();
      this.computCellData()
      window.addEventListener('resize', this.computCellData, true)

      let i = new Isotope('.grid__wrapper', {
        layoutMode: 'fitRows',
        itemSelector: '.grid__item',
        percentPosition: true,
        transitionDuration: 0
      });

      t.setState({ iso: i }, () => {
        t.state.iso.arrange({
          filter: "*" // All grid items
        });
      });
    }
  }

  componentWillUnmount() {
    if (isBrowser) {
      window.removeEventListener('resize', this.computCellData, true)
      clearTimeout(timeoutHandle);
      this.state.iso.destroy()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.ready) {
      if (this.state !== nextState) {
        return true
      }
    }

    return false
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.initLayout) {
      // Update layout grid.
      let event = new CustomEvent('resize');
      document.scrollingElement.dispatchEvent(event);
      this.setState({ initLayout: true });
    }
    this.state.iso.arrange();
  }

  getLocation = async () => {
    let locations = this.props.data.allNodeLocation.nodes;

    const allPromise = Promise.all(locations.map(async (location_address) => {
      return await this.fetchLocation(location_address)
    }));
    const data = await allPromise;

    this.setState({
      locationData: data,
      ready: true,
    });
  }

  fetchLocation = async (address) => {
    let location_address = encodeURI(`${address.field_street}+${address.field_street_number},+${address.field_postal_code}+${address.field_city}`)
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${location_address}&key=${process.env.GMAP_API_KEY}`)
    .then(response => response.json())
    .then(response => {
      if (response) {
        let l = response.results[0].geometry.location;
        this.setState({
          center: {
            lat: l.lat,
            lng: l.lng,
          },
        });
        return l;
      }
    })
    .catch(err => console.log(err))
    return response
  }

  computCellData() {
    if (isBrowser) {
      let cellsize = 0;
      let width = document.body.clientWidth;

      if (width > 960) {
        cellsize = width / 48
      }
      else if (width > 480) {
        cellsize = width / 24
      }
      else {
        cellsize = width / 12
      }

      cellsize = (Math.round(cellsize * 100) / 100).toFixed(2)

      this.setState({
        cellSize: cellsize,
      });
    }
  }

  render() {
    let group_images = this.props.data.sitePage.images_single_group;
    let group = this.props.pageContext.data;
    let person_nodes = this.props.data.allSitePage.nodes;

    let pictures = [];
    if (group_images !== null) {
      pictures = group_images
    }

    let title = group.title;
    let story = group.body

    if (story !== null) {
      // Set correct url.
      story = story.processed
      story = story.replace(/\/sites/g, `${process.env.BACKEND_URL}/sites`);
      story = story.replace(/\/media\/oembed/g, `${process.env.BACKEND_URL}/media/oembed`);
      story = { __html: story }
    }

    const GoogleMapMarker = ({ text }) => <div className="marker">{text}</div>;

    let backLink = '/groepen/'
    if (this.props.location.state) {
      backLink = this.props.location.state.prevPath
    }

    let metaImage;
    if (pictures.length !== 0 && pictures[0].childImageSharp !== null) {
      metaImage = pictures[0].childImageSharp.gatsbyImageData.images.fallback.src
    }
    else if (person_nodes[0] && person_nodes[0].images_single_person) {
      metaImage = person_nodes[0].images_single_person[0].childImageSharp.gatsbyImageData.images.fallback.src
    }

    let styleIndex = 0;
    let cell = this.state.cellSize
    let style = styleList[0];

    let person_data = person_nodes.map((person, index) => {
      let image = person.images_single_person;

      if (image === null) {
        return null
      }

      image = <GatsbyImage image={image[0].childImageSharp.gatsbyImageData} alt={title + ' ' + (index + 1)} />

      if (styleList[styleIndex]) {
        style = styleList[styleIndex];
      }

      let main_style = {
        width: cell * style['width'],
        height: cell * style['height'],
      }

      let inner_style = {
        marginTop: cell * style['marginTop'],
        marginRight: cell * style['marginRight'],
        marginBottom: cell * style['marginBottom'],
        marginLeft: cell * style['marginLeft'],
        width: main_style.width - (cell * (style['marginLeft'] + style['marginRight'])),
        height: main_style.height - (cell * (style['marginTop'] + style['marginBottom'])),
      }

      // Set style index.
      if (styleIndex >= styleList.length - 1) {
        // Last style item was reached.
        styleIndex = 0
      }
      else {
        styleIndex++;
      }

      return (
        <div key={index} style={main_style} className="grid__item">
          <div className="grid__item--inner" style={inner_style}>
            <div className="grid__item--inner-wrapper">
              {image !== null && image}
            </div>
          </div>
        </div>
      )
    })

    // Build social links array.
    let group_links = {};
    group_links = Object.keys(group).map((key) => {
      if (key.includes("field_links_") && group[key]) {

        if (group[key] === 'N' || group[key] === '/') {
          return null
        }

        var data = {};
        let icon_name = key.replace(/field_links_/g, '')
        icon_name = icon_name[0].toUpperCase() + icon_name.substring(1);
        let link_title = icon_name.replace(/_/g, ' ');

        if (link_title.includes(" page")) {
          link_title = link_title.replace(/ page/g, '')
        }

        data['title'] = link_title;
        data['link'] = group[key];
        data['icon'] = icon_name;

        return data;
      }
      else {
        return null
      }
    });

    // Clean empty items.
    group_links = group_links.filter(n => n)

    let shareUrl = this.props.location.href;

    let adres = null;

    if (this.props.data.allNodeLocation.nodes[0]) {
      adres = this.props.data.allNodeLocation.nodes[0];
    }

    return (
      <>
        <Seo
          title={title}
          pathname={group.path.alias}
          description={group.field_meta_description}
          article={true}
          image={metaImage}
          locality={adres !== null ? adres.field_city : null }
          address={adres !== null ? adres.field_street + " " + adres.field_street_number : null }
        />

        <SocialShareBanner title={title} url={shareUrl} media={metaImage}></SocialShareBanner>

        <div className="group__wrapper">
          <div className="group__block">
            <div className="group__img--wrapper">
              <Link to={backLink} className="group__img--link">Naar overzicht</Link>
              {pictures.length !== 0 &&
                <div className="group__img">
                  <Slider images={pictures}></Slider>
                </div>
              }
            </div>

            <div className="group__title">
              <span>{title}</span>
            </div>

            {story && person_data.length > 0 &&
              <a href="#persons" className="group__story--skip">Naar alle portretten</a>
            }

            <div className={"group__story" + (story === null ? ' no-story' : '') + (group_links.length === 0 ? ' no-links' : '')}>
              {story &&
                <div className="group__story--text" dangerouslySetInnerHTML={story}></div>
              }
              {group_links.length > 0 &&
                <ul className="group__story--links">
                  {group_links.map((group_data, index) => {
                    return (
                      <SocialLink key={index} title={group_data.title} link={group_data.link} icon={group_data.icon} />
                    )
                  })}
                </ul>
              }
            </div>

            { this.state.locationData.length > 0 &&
              <div className="group__markers">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: `${process.env.GMAP_KEY}` }}
                  defaultCenter={this.state.center}
                  defaultZoom={this.state.zoom}
                >
                  { this.state.locationData.map((location, index) => {
                    return(
                      <GoogleMapMarker key={index} lat={location.lat} lng={location.lng} text={title} />
                    )
                  })}
                </GoogleMapReact>
              </div>

            }

          </div>

          <div className="group__block">
            <div id="persons" className="group__persons grid__wrapper">
              {person_data}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const query = graphql`
query ($id: Int!) {
  sitePage(context: {id: {eq: $id}, amp: {eq: false}}) {
    images_single_group {
      childImageSharp {
        gatsbyImageData (
          width: 890
          layout: CONSTRAINED
          quality: 75
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP]
        )
      }
    }
  }
  allSitePage(
    filter: {context: {data: {relationships: {field_ref_group: {drupal_internal__nid: {eq: $id}}}}, bundle: {eq: "Person"}, amp: {eq: false}}}
  ) {
    nodes {
      images_single_person {
        childImageSharp {
          gatsbyImageData (
            width: 400
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
            aspectRatio: 0.66
          )
        }
      }
    }
  }
  allNodeLocation(filter: {relationships: {field_ref_group: {drupal_internal__nid: {eq: $id}}}}) {
    nodes {
      field_street_number
      field_street
      field_postal_code
      field_company
      field_city
    }
  }
}
`

export default Groep
